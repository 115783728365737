import { Box, Button, Grid, Heading, Text, Link, HStack } from "@chakra-ui/react";
import { useClerk } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";

import { ThemeColors } from "~/styles/theme";

const Icon403 = ({
  size = 200,
  backgroundColor = "#f3f4f6",
  strokeColor = ThemeColors.brand.primaryValue,
  textColor = "#6b7280"
}) => {
  return (
    <div className="inline-block">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 200 200"
        width={size}
        height={size}
        className="w-full h-full"
      >
        {/* Background */}
        <circle cx="100" cy="100" r="90" fill={backgroundColor} />

        {/* Lock shape */}
        <rect x="70" y="90" width="60" height="50" rx="5" fill={strokeColor} />
        <path
          d="M85 90 L85 70 Q100 50 115 70 L115 90"
          fill="none"
          stroke={strokeColor}
          strokeWidth="8"
          strokeLinecap="round"
        />

        {/* 403 text */}
        <text
          x="100"
          y="165"
          fontFamily="Arial, sans-serif"
          fontSize="24"
          fontWeight="bold"
          fill={textColor}
          textAnchor="middle"
        >
          403
        </text>
      </svg>
    </div>
  );
};

const Page403 = () => {
  const navigate = useNavigate();
  const clerk = useClerk();

  const handleBackToHome = () => navigate("/");
  const handleSignOut = () => {
    clerk.signOut(() => {
      window.location.href = "/signin";
    });
  };

  return (
    <Grid gap={4} textAlign="center" p="10">
      <Heading>Access Forbidden</Heading>
      <Box maxWidth={[280, 400]} marginX="auto">
        <Icon403 />
      </Box>
      <Box>
        <Text>
          You are not authorized to access the Worry Work Admin Portal. Visit the{" "}
          <Link
            href="https://theworrywork.app/"
            color="brand.primary"
            isExternal
            textDecoration="underline"
            _hover={{ fontWeight: "bold" }}
          >
            Worry Work website
          </Link>{" "}
          to learn more.
        </Text>
      </Box>
      <Box>
        <HStack spacing={4} justify="center">
          <Button variant="secondary" onClick={handleBackToHome}>
            Go back home
          </Button>
          <Button variant="outline" colorScheme="red" onClick={handleSignOut}>
            Sign out
          </Button>
        </HStack>
      </Box>
    </Grid>
  );
};

export default Page403; 