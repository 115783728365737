import { Box, Input, Button, Text, Heading, Flex, Textarea, InputProps } from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { AvDatePicker } from "~/components/AvDatePicker";
import { ThemeColors } from "~/styles/theme";

export interface OrganizationFormData {
  id?: number;
  name: string;
  domains?: string; // Comma-separated string in the form
  serviceStartDate?: Date | string;
  serviceEndDate?: Date | string;
  adminEmails?: string; // Comma-separated emails instead of UserInfo[]
  maxUsers?: number;
}

interface OrgFormProps {
  defaultValues?: OrganizationFormData;
  onSubmit: (data: OrganizationFormData) => void;
  onCancel: () => void;
  onDelete?: () => void;
  inputProps?: InputProps;
}

export const OrgForm = ({ defaultValues, onSubmit, onCancel, inputProps }: OrgFormProps) => {
  const { register, handleSubmit, control } = useForm<OrganizationFormData>({
    defaultValues
  });
  const navigate = useNavigate();
  const inputWidth = { base: "90vw", md: "500px" };
  return (
    <Box ml={{ base: 0, md: 40 }} mt={30}>
      <Heading as="h2" size="md">
        About the organization
      </Heading>
      <Box m={{ base: 0, md: 4 }} mt={{ base: 4, md: 0 }}>
        <form
          onSubmit={handleSubmit((data) => {
            onSubmit(data);
          })}
        >
          <Flex direction="column" gap={4} width="100%">
            <Flex align="center" gap={4} flexWrap={{ base: "wrap", md: "nowrap" }}>
              <Text minW="200px" whiteSpace="nowrap">
                Organization Name:
              </Text>
              <Input placeholder="Name" {...register("name")} bg="white" width={inputWidth} {...inputProps} />
            </Flex>
            <Flex align="center" gap={4} flexWrap={{ base: "wrap", md: "nowrap" }}>
              <Text minW="200px" whiteSpace="nowrap">
                Email Domain(s):
              </Text>
              <Input
                placeholder="avodah.dev, wwa.co, google.com"
                {...register("domains")}
                bg="white"
                width={inputWidth}
                {...inputProps}
              />
            </Flex>

            <Flex align="center" gap={4} flexWrap={{ base: "wrap", md: "nowrap" }}>
              <Text minW="200px" whiteSpace="nowrap">
                Service Start Date:
              </Text>
              <Controller
                name="serviceStartDate"
                control={control}
                render={({ field }) => (
                  <AvDatePicker
                    selectedDate={field.value ? new Date(field.value) : null}
                    onChange={(date) => field.onChange(date)}
                    inputProps={{
                      size: "md",
                      width: inputWidth,
                      placeholder:
                        inputProps?.isDisabled && defaultValues?.serviceStartDate
                          ? new Date(defaultValues.serviceStartDate).toLocaleDateString()
                          : "Select Start Date...",
                      ...inputProps
                    }}
                  />
                )}
              />
            </Flex>
            <Flex align="center" gap={4} flexWrap={{ base: "wrap", md: "nowrap" }}>
              <Text minW="200px" whiteSpace="nowrap">
                Service End Date:
              </Text>
              <Controller
                name="serviceEndDate"
                control={control}
                render={({ field }) => (
                  <AvDatePicker
                    selectedDate={field.value ? new Date(field.value) : null}
                    onChange={(date) => field.onChange(date)}
                    inputProps={{
                      size: "md",
                      width: inputWidth,
                      ...inputProps,
                      placeholder:
                        inputProps?.isDisabled && defaultValues?.serviceEndDate
                          ? new Date(defaultValues.serviceEndDate).toLocaleDateString()
                          : "Select End Date..."
                    }}
                  />
                )}
              />
            </Flex>
            <Flex align="center" gap={4} flexWrap={{ base: "wrap", md: "nowrap" }}>
              <Text minW="200px" whiteSpace="nowrap">
                Number of users
              </Text>
              <Input
                placeholder={defaultValues?.maxUsers?.toString() || "Number of users"}
                {...register("maxUsers")}
                bg="white"
                width={inputWidth}
                {...inputProps}
              />
            </Flex>
            {!inputProps?.isDisabled && (
              <>
                <Flex align={defaultValues ? "center" : "top"} gap={4} flexWrap={{ base: "wrap", md: "nowrap" }}>
                  <Text minW="200px" whiteSpace="nowrap">
                    Organization Admin(s):
                  </Text>
                  {defaultValues ? (
                    <Button
                      onClick={() => navigate(`/orgs/${defaultValues.id}/admins`)}
                      minWidth="100px"
                      variant="outline"
                      type="submit"
                    >
                      Manage Admins
                    </Button>
                  ) : (
                    <Textarea
                      placeholder="janedoe@acme.co, joshdoe@acme.co"
                      resize="vertical"
                      {...register("adminEmails")}
                      bg="white"
                      width={inputWidth}
                      borderRadius={3}
                      height="40px"
                      maxLength={1000}
                    />
                  )}
                </Flex>

                <Flex mt={4} gap={2} flexWrap={{ base: "wrap", md: "nowrap" }} width="100%" justify="flex-end">
                  <Button
                    onClick={onCancel}
                    variant="ghost"
                    w={{ base: "90vw", md: "auto" }}
                    color={ThemeColors.brand.primary}
                    minWidth="100px"
                  >
                    Cancel
                  </Button>
                  <Button type="submit" variant="outline" w={{ base: "90vw", md: "auto" }} minWidth="100px">
                    Save
                  </Button>
                </Flex>
              </>
            )}
          </Flex>
        </form>
      </Box>
    </Box>
  );
};
