import { AuditLog } from "@shared/domain.models";
import { useQuery, UseQueryResult } from "@tanstack/react-query";

interface UseAuditLogsOptions {
  orgId?: number;
}

export function useAuditLogs(options: UseAuditLogsOptions = {}) {
  const useAllAuditLogs = (): UseQueryResult<AuditLog[]> =>
    useQuery<AuditLog[]>({
      queryKey: ["audit-logs"],
      queryFn: async () => {
        const response = await fetch("/api/audit-logs");
        if (!response.ok) {
          throw new Error("Failed to fetch audit logs");
        }
        return response.json();
      }
    });

  const useAuditLogsByOrg = (orgId: number): UseQueryResult<AuditLog[]> =>
    useQuery<AuditLog[]>({
      queryKey: ["audit-logs", orgId],
      queryFn: async () => {
        const response = await fetch(`/api/audit-logs/${orgId}`);
        if (!response.ok) {
          throw new Error("Failed to fetch audit logs for org");
        }
        return response.json();
      },
      enabled: !!orgId // Only run if orgId is truthy
    });

  const query = options.orgId ? useAuditLogsByOrg(options.orgId) : useAllAuditLogs();
  const logs = query.data ?? [];

  // Filter logs by category
  const organizationLogs = logs.filter((log) => log.category === "Organization");
  const adminLogs = logs.filter((log) => log.category === "Admins");
  const appVersionLogs = logs.filter((log) => log.category === "AppVersion");

  return {
    logs,
    isLoading: query.isLoading,
    error: query.error,
    organizationLogs,
    adminLogs,
    appVersionLogs
  };
}
