import { useEffect } from "react";

import { Box, ChakraProvider } from "@chakra-ui/react";
import { useUser } from "@clerk/clerk-react";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter as Router } from "react-router-dom";

import { RichWindow } from "./globals";

import Meta from "~/layout/Meta";
import Routings from "~/router/Routings";
import { theme } from "~/styles/theme";

export const queryClient = new QueryClient();

const App = () => {
  const user = useUser();

  useEffect(() => {
    if (!user.isLoaded) return;

    if (user.isSignedIn) {
      RichWindow.heap.identify(user.user?.primaryEmailAddress?.emailAddress.toLocaleLowerCase());
    }
  }, [user.isSignedIn]);

  return (
    <QueryClientProvider client={queryClient}>
      <Sentry.ErrorBoundary fallback={<p>Something went wrong</p>}>
        <Meta />
        <Box className="id--app">
          <ChakraProvider theme={theme} resetCSS={true}>
            <Router>
              <Routings />
            </Router>
          </ChakraProvider>
        </Box>
      </Sentry.ErrorBoundary>
    </QueryClientProvider>
  );
};

export default App;
