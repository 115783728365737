import { Box, Text, Flex } from "@chakra-ui/react";

interface StatsBubbleProps {
  header?: string;
  body: React.ReactNode;
  children?: React.ReactNode;
}

export const StatsBubble = ({ header, body, children }: StatsBubbleProps) => {
  return (
    <Flex direction="column" p={{ base: 4, md: 6 }} bg="gray.200" borderRadius="10" width="100%" height="100%" gap={1}>
      <Box>
        <Text fontWeight="bold">{header || "\u00A0"}</Text>
      </Box>

      <Box>{body || "\u00A0"}</Box>
      <Box>{children || "\u00A0"}</Box>
    </Flex>
  );
};
