import { useState } from "react";

import { Box, Button, Divider, Flex, Heading, Select, Spinner } from "@chakra-ui/react";

import { AdminList } from "~/components/AdminList";
import { ExportButton } from "~/components/ExportButton";
import { InviteButton } from "~/components/InviteButton";
import { LogRow } from "~/components/LogRow";
import { PageHeading } from "~/components/PageHeading";
import { UnauthorizedErrorMessage } from "~/components/UnauthorizedErrorMessage";
import { useAuditLogs } from "~/hooks/useAuditLogs";
import { useDbOrgs } from "~/hooks/useDbOrgs";
import { useDbUsers } from "~/hooks/useDbUsers";
import { exportSuperAdminsToExcel } from "~/services/local-export-service";
import { cleanFilename } from "~/utils/filename-util";

const SuperAdminListPage = () => {
  const { useSuperAdmins, usePendingSuperAdmins } = useDbUsers();
  const { logs, isLoading: logsLoading } = useAuditLogs();
  const { useAllOrgs } = useDbOrgs();
  const { data: users, isLoading: userLoading, error: useSuperAdminsError } = useSuperAdmins();
  const { data: pendingInvites, isLoading: pendingLoading } = usePendingSuperAdmins();
  const [showLogs, setShowLogs] = useState(false);
  const { data: allOrgs, error: allOrgsError, isLoading: allOrgsLoading } = useAllOrgs();
  const [orgFilter, setOrgFilter] = useState<string>("All");

  const exportFileName = "WWA_Super_Admins";

  // Combine active users and pending invites for export
  const allAdmins = [
    ...(users || []),
    ...(pendingInvites || []).map((invite) => ({
      ...invite,
      emailAddress: invite.email
    }))
  ];

  const filteredLogs = logs?.filter((log) => {
    if (orgFilter === "All") {
      return true;
    }
    const matchesOrg = allOrgs?.find((o) => o.id === log.orgId)?.name === orgFilter;
    return matchesOrg;
  });

  if (useSuperAdminsError || allOrgsError) {
    return (
      <Flex w="100%" h="100vh" justify="center" align="center">
        <UnauthorizedErrorMessage error={useSuperAdminsError?.message || allOrgsError?.message || ""} />
      </Flex>
    );
  }

  if (userLoading || logsLoading || allOrgsLoading || pendingLoading) {
    return (
      <Flex w="100%" h="100vh" justify="center" align="center">
        <Spinner />
      </Flex>
    );
  }

  return (
    <Flex direction="column" align="start">
      <PageHeading text="Super Admin List" />
      <Divider mb={4} />
      <Box w="100%">
        <Flex justify="space-between" align="center" mb={4}>
          <Heading size="md">Super Admins</Heading>
          <Flex gap={4}>
            <InviteButton invitingSuper orgId={0} />
            <ExportButton onClick={() => exportSuperAdminsToExcel(allAdmins, cleanFilename(exportFileName))} />
            <Button onClick={() => setShowLogs(!showLogs)}>{showLogs ? "Hide Logs" : "Show Logs"}</Button>
          </Flex>
        </Flex>

        <AdminList admins={users || []} pendingInvites={pendingInvites || []} />

        {showLogs && (
          <Box>
            <Heading size="sm" mb={2} mt={8}>
              Activity Log
            </Heading>
            <Flex gap={4} mb={4}>
              <Select
                value={orgFilter}
                onChange={(e) => setOrgFilter(e.target.value)}
                placeholder="Filter by Organization"
                maxW="300px"
              >
                <option value="All">All Organizations</option>
                {allOrgs?.map((org) => (
                  <option key={org.id} value={org.name}>
                    {org.name}
                  </option>
                ))}
              </Select>
            </Flex>
            {filteredLogs?.map((log) => (
              <LogRow
                key={log.id}
                date={new Date(log.created_at ?? "")}
                body={log.action}
                category={log.category}
                orgName={allOrgs?.find((o) => o.id === log.orgId)?.name || ""}
                firstName={log.user?.firstName ?? ""}
                lastName={log.user?.lastName ?? ""}
                emailAddress={log.user?.emailAddress ?? ""}
                isSuperAdmin={log.user?.isSuperAdmin}
              />
            ))}
          </Box>
        )}
      </Box>
    </Flex>
  );
};

export default SuperAdminListPage;
