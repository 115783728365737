import { useState } from "react";

import {
  Popover,
  PopoverTrigger,
  Button,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  HStack,
  Input,
  useToast,
  useDisclosure,
  ButtonProps,
  FormControl,
  FormErrorMessage
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { IoPersonAddSharp } from "react-icons/io5";

import useAuditLogMutations from "~/hooks/useAuditLogMutations";
import { useDbOrgs } from "~/hooks/useDbOrgs";
import { useDbUsers } from "~/hooks/useDbUsers";

interface InviteButtonProps {
  invitingSuper?: boolean;
  orgId: number;
  buttonProps?: ButtonProps;
}

interface ApiResponse {
  message?: string;
  error?: string;
}

export const InviteButton = ({ invitingSuper = false, orgId, buttonProps }: InviteButtonProps) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { useLogAdminAction } = useAuditLogMutations();
  const logAdminAction = useLogAdminAction();

  const { useGetOrgById } = useDbOrgs();
  const { data: org } = useGetOrgById(orgId);

  const { useInviteOrgAdmin } = useDbUsers();
  const inviteOrgAdmin = useInviteOrgAdmin();

  const inviteMutation = useMutation<ApiResponse, Error>({
    mutationFn: async (): Promise<ApiResponse> => {
      if (invitingSuper) {
        const response = await fetch("/api/admin-invites/invite-super-admin", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ email })
        });

        const data = await response.json();

        if (!response.ok) {
          const errorMsg = data.error || `Failed to send invite (Status: ${response.status})`;
          throw new Error(errorMsg);
        }

        return data;
      } else {
        return await inviteOrgAdmin.mutateAsync({ email, orgId });
      }
    },
    onSuccess: () => {
      toast({
        status: "success",
        title: "Invite sent successfully",
        description: "An email has been sent to the user with instructions to join."
      });
      setEmail("");
      if (invitingSuper) {
        logAdminAction.mutate({
          action: `Added Super Admin: ${email}`,
          orgId: 0
        });
      } else {
        logAdminAction.mutate({
          action: `Invited Admin: ${email}`,
          orgId
        });
      }
      onClose();
    },
    onError: (error: Error) => {
      const errorMessage = error.message;
      const isKnownError = errorMessage.includes("already been invited") || errorMessage.includes("already a member");

      toast({
        status: isKnownError ? "warning" : "error",
        title: isKnownError ? "Unable to send invite" : "Failed to send invite",
        description: errorMessage,
        duration: 5000
      });
    }
  });

  const validateEmail = (email: string) => {
    if (!email) {
      setEmailError("Email is required");
      return false;
    }

    if (!org && !invitingSuper) {
      setEmailError("Organization data not available");
      return false;
    }

    const emailDomain = email.split("@")[1];
    if (!emailDomain) {
      setEmailError("Invalid email format");
      return false;
    }

    if (!invitingSuper && !org?.domains.includes(emailDomain)) {
      setEmailError(`Email domain must be one of: ${org?.domains.join(", ")}`);
      return false;
    }

    setEmailError("");
    return true;
  };

  const handleInvite = () => {
    if (!validateEmail(email)) {
      return;
    }
    inviteMutation.mutate();
  };

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button variant="outline" leftIcon={<IoPersonAddSharp />} onClick={onOpen} {...buttonProps}>
          {invitingSuper ? "Invite Super Admin" : "Invite Admin"}
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody>
            <FormControl isInvalid={!!emailError}>
              <HStack>
                <Input
                  type="email"
                  placeholder="Admin Email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    if (emailError) validateEmail(e.target.value);
                  }}
                  onKeyDown={(e) => e.key === "Enter" && handleInvite()}
                />
                <Button
                  variant="outline"
                  onClick={handleInvite}
                  {...buttonProps}
                  isLoading={inviteMutation.isPending}
                  disabled={inviteMutation.isPending}
                >
                  Invite
                </Button>
              </HStack>
              {emailError && <FormErrorMessage>{emailError}</FormErrorMessage>}
            </FormControl>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
