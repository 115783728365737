import { ReactNode } from "react";

import { HStack, IconButton, Heading } from "@chakra-ui/react";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";

import { ThemeColors } from "~/styles/theme";

interface PageHeadingProps {
  text: string;
  hasBackButton?: boolean;
  children?: ReactNode;
}

export const PageHeading = ({ text, hasBackButton = false, children }: PageHeadingProps) => {
  const navigate = useNavigate();

  const handleBack = () => {
    if (window.history.length > 2) {
      navigate(-1);
    } else {
      navigate("/");
    }
  };

  return (
    <HStack
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      mb={{ base: 0, md: 4 }}
      flexWrap={{ base: "wrap", md: "nowrap" }}
    >
      <HStack>
        {hasBackButton && (
          <IconButton
            aria-label="Back"
            onClick={handleBack}
            variant="ghost"
            color={ThemeColors.brand.primary}
            icon={<IoIosArrowRoundBack size={50} />}
          />
        )}
        <Heading size={{ base: "lg", md: "xl" }}>{text}</Heading>
      </HStack>
      {children}
    </HStack>
  );
};
