import React from "react";

import { Avatar, Box, Flex, Text, useTheme, HStack, VStack, Badge } from "@chakra-ui/react";

interface LogRowProps {
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  profileImageUrl?: string;
  date: Date;
  body: string;
  orgName?: string;
  category?: string;
  isSuperAdmin?: boolean;
}

export const LogRow = ({
  firstName,
  lastName,
  emailAddress,
  profileImageUrl,
  date,
  body,
  orgName,
  category,
  isSuperAdmin
}: LogRowProps) => {
  const theme = useTheme();

  // Compute initials for fallback avatar
  const initials = React.useMemo(() => {
    const fn = firstName?.charAt(0).toUpperCase() ?? "";
    const ln = lastName?.charAt(0).toUpperCase() ?? "";
    return fn + ln || "?";
  }, [firstName, lastName]);

  // Generate a simple hash to select a color from the pastel palette
  const pastelKeys = Object.keys(theme.colors.pastels) as (keyof typeof theme.colors.pastels)[];
  const colorIndex = React.useMemo(() => {
    const nameString = (firstName || "") + (lastName || "");
    const hash = Array.from(nameString).reduce((acc, char) => acc + char.charCodeAt(0), 0);
    return hash % pastelKeys.length;
  }, [firstName, lastName, pastelKeys]);

  const bgColor = theme.colors.pastels[pastelKeys[colorIndex]];
  const formattedDate = date.toLocaleString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true
  });

  return (
    <Flex alignItems="center" w="100%" gap={4}>
      {/* Profile Picture or Initials */}
      {profileImageUrl ? (
        <Avatar src={profileImageUrl} name={`${firstName} ${lastName}`} />
      ) : (
        <Flex w="60px" h="60px" borderRadius="full" alignItems="center" justifyContent="center" bg={bgColor}>
          <Text fontWeight="bold" fontSize="lg">
            {initials}
          </Text>
        </Flex>
      )}

      {/* Body and Date */}
      <Box>
        <Text fontWeight="bold">
          {category && `${category}: `} {body} {orgName ?? orgName}
        </Text>
        <VStack spacing={0} align="flex-start">
          <HStack spacing={2}>
            {isSuperAdmin && <Badge colorScheme="purple" style={{ borderRadius: 3, padding: 2, paddingLeft: 8, paddingRight: 8 }}>WWA Super Admin</Badge>}
            <Text fontSize="sm">{`${firstName ?? ""} ${lastName ?? ""} (${emailAddress ?? ""})`}</Text>
          </HStack>
          <Text fontSize="sm" color="gray.500">
            {formattedDate}
          </Text>
        </VStack>
      </Box>
    </Flex>
  );
};
