import { Flex, List, ListItem, Grid, Spinner, Text, Button, HStack, Divider, Box } from "@chakra-ui/react";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import { ExportButton } from "~/components/ExportButton";
import { OrgItem } from "~/components/OrgItem";
import { PageHeading } from "~/components/PageHeading";
import { useDbOrgs } from "~/hooks/useDbOrgs";
import { exportOrgListToExcel } from "~/services/local-export-service";
// const filterByNameOrDomain = (org: Organization, filterText: string) => {
//   // If no filter text, show all
//   if (!filterText.trim()) return true;
//   const lowerFilter = filterText.toLowerCase();
//   return (
//     org.name.toLowerCase().includes(lowerFilter) ||
//     org.domains.some((domain: string) => domain.toLowerCase().includes(lowerFilter))
//   );
// };

// const filterByStatus = (org: Organization, status: string) => {
//   if (status === "All") return true;
//   if (status === "Active") return org.isActive === true;
//   if (status === "Inactive") return org.isActive === false;
//   return true;
// };

// const filterByExpiration = (org: Organization, date: Date | null) => {
//   // If no date chosen, do not filter by expiration
//   if (!date) return true;
//   if (!org.serviceEndDate) return false;
//   const orgEnd = new Date(org.serviceEndDate);
//   return orgEnd <= date;
// };

const OrgListPage = () => {
  const { useAllOrgs } = useDbOrgs();
  const { data: orgs, isLoading, error } = useAllOrgs();

  // const [statusFilter, setStatusFilter] = useState<string>("All");
  // const [orgFilter, setOrgFilter] = useState<string>("");
  // const [dateFilter, setDateFilter] = useState<Date | null>(null);

  const navigate = useNavigate();

  const sortedOrgs = (orgs || []).sort((a, b) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const aDate = a.serviceEndDate ? new Date(a.serviceEndDate) : null;
    const bDate = b.serviceEndDate ? new Date(b.serviceEndDate) : null;

    // If either date is null, put them at the end
    if (!aDate && !bDate) return 0;
    if (!aDate) return 1;
    if (!bDate) return -1;

    // If either date is before today, put them at the end
    const aExpired = aDate < today;
    const bExpired = bDate < today;

    if (aExpired && !bExpired) return 1;
    if (!aExpired && bExpired) return -1;
    if (aExpired && bExpired) return 0;

    // Both dates are valid and not expired, sort by date
    return aDate.getTime() - bDate.getTime();
  });

  // const filteredOrgs = useMemo(() => {
  //   if (!orgs) return [];
  //   return orgs.filter((org) => {
  //     return (
  //       filterByNameOrDomain(org, orgFilter) && filterByStatus(org, statusFilter) && filterByExpiration(org, dateFilter)
  //     );
  //   });
  // }, [orgs, orgFilter, statusFilter, dateFilter]);

  if (isLoading) {
    return (
      <Flex align="center" justify="center" height="100vh">
        <Spinner size="xl" />
        <Text ml={4}>Loading Organizations...</Text>
      </Flex>
    );
  }

  if (error instanceof Error) {
    return (
      <Flex align="center" justify="center" height="100vh">
        <Text color="red.500">Error: {error.message}</Text>
      </Flex>
    );
  }

  return (
    <Flex direction="column" align="start" overflowX="auto" w="100%" gap={2}>
      <PageHeading text="Organizations">
        <HStack spacing={2} mt={{ base: 2, md: 0 }} flexWrap={{ base: "wrap", md: "nowrap" }}>
          <Button
            variant="outline"
            onClick={() => navigate("/orgs/new")}
            leftIcon={<FaPlus />}
            size={{ base: "sm", md: "md" }}
          >
            Add Organization
          </Button>
          <ExportButton onClick={() => exportOrgListToExcel(sortedOrgs, "WWA_Organizations.xlsx")} />
        </HStack>
      </PageHeading>
      <Divider my={4} />
      <Box
        borderRadius={10}
        border="1px solid"
        borderColor="gray.200"
        p={{ base: 1, md: 4 }}
        w="100%"
        maxW="100vw"
        overflowX="auto"
      >
        <List width="100%">
          <ListItem key="header" p={4}>
            <Grid
              templateColumns={{
                base: "2fr 1fr 40px",
                md: "repeat(5, 1fr) 40px"
              }}
              gap={4}
              alignItems="center"
            >
              <Text color="gray.500">Organization Name</Text>
              <Text color="gray.500" display={{ base: "none", md: "block" }}>
                Email Domains
              </Text>
              <Text color="gray.500">Status</Text>
              <Text color="gray.500" display={{ base: "none", md: "block" }}>
                Start Date
              </Text>
              <Text color="gray.500" display={{ base: "none", md: "block" }}>
                Expiration Date
              </Text>
            </Grid>
          </ListItem>
          {sortedOrgs.map((org) => (
            <OrgItem org={org} key={org.id} />
          ))}
          {sortedOrgs.length === 0 && <Text>No organizations found.</Text>}
        </List>
      </Box>
    </Flex>
  );
};

export default OrgListPage;
