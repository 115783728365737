import { useClerk, useSession } from "@clerk/clerk-react";
import { UserInfo, UserRole } from "@shared/domain.models";
import { useMutation, useQuery, UseQueryResult } from "@tanstack/react-query";

import { useApiErrorHandler } from "./useApiErrorHandler";

import { queryClient } from "~/App";

interface OrgUsersResponse {
  users: UserInfo[];
  pendingInvites: {
    email: string;
    createdAt: string;
    expiresAt: string;
  }[];
}

interface PendingInvite {
  email: string;
  createdAt: Date;
  expiresAt: Date;
}

export function useDbUsers() {
  const handleError = useApiErrorHandler();
  const { isLoaded, isSignedIn } = useSession();

  const useGetAllUsers = (): UseQueryResult<UserInfo[]> =>
    useQuery<UserInfo[]>({
      queryKey: ["users"],
      queryFn: async () => {
        try {
          const response = await fetch("/api/users");
          if (!response.ok) {
            throw await handleError(response);
          }
          return response.json();
        } catch (error) {
          throw await handleError(error);
        }
      }
    });

  const useGetOrgUsers = (orgId: number): UseQueryResult<{ users: UserInfo[]; pendingInvites: PendingInvite[] }> =>
    useQuery({
      queryKey: ["org_users", orgId],
      queryFn: async () => {
        try {
          const response = await fetch(`/api/users/org/${orgId}`);
          if (!response.ok) {
            throw await handleError(response);
          }
          const data = (await response.json()) as OrgUsersResponse;
          return {
            users: data.users.map((user: UserInfo) => ({
              ...user,
              createdAt: new Date(user.createdAt)
            })),
            pendingInvites: data.pendingInvites.map((invite) => {
              const createdDate = new Date(invite.createdAt + " 00:00:00");
              const expiresDate = new Date(invite.expiresAt + " 00:00:00");
              return {
                email: invite.email,
                createdAt: createdDate,
                expiresAt: expiresDate
              };
            })
          };
        } catch (error) {
          throw await handleError(error);
        }
      }
    });

  const useSuperAdmins = (): UseQueryResult<UserInfo[]> =>
    useQuery<UserInfo[]>({
      queryKey: ["superadmins"],
      queryFn: async () => {
        try {
          const response = await fetch("/api/super-admins");
          if (!response.ok) {
            throw await handleError(response);
          }
          return response.json();
        } catch (error) {
          throw await handleError(error);
        }
      }
    });

  const usePendingSuperAdmins = (): UseQueryResult<PendingInvite[]> =>
    useQuery<PendingInvite[]>({
      queryKey: ["pending_super_admins"],
      queryFn: async () => {
        try {
          const response = await fetch("/api/users/pending-super-admins");
          if (!response.ok) {
            throw await handleError(response);
          }
          const data = await response.json();
          return data.map((invite: { email: string; createdAt: string; expiresAt: string }) => ({
            email: invite.email,
            createdAt: new Date(invite.createdAt + " 00:00:00"),
            expiresAt: new Date(invite.expiresAt + " 00:00:00")
          }));
        } catch (error) {
          throw await handleError(error);
        }
      }
    });

  const useSetUserRole = () => {
    const mutation = useMutation<UserInfo, Error, { userId: number; role: UserRole }>({
      mutationFn: async ({ userId, role }) => {
        try {
          const response = await fetch(`/api/users/${userId}/set-role`, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({ role })
          });
          if (!response.ok) {
            throw await handleError(response);
          }
          const updatedUser = await response.json();
          queryClient.invalidateQueries({ queryKey: ["users"] });
          return updatedUser;
        } catch (error) {
          throw await handleError(error);
        }
      }
    });
    return (userId: number, role: UserRole) => mutation.mutateAsync({ userId, role });
  };

  const useUserInfo = (): UseQueryResult<UserInfo> =>
    useQuery<UserInfo>({
      queryKey: ["userInfo"],
      queryFn: async () => {
        try {
          const response = await fetch("/api/me");
          if (!response.ok) {
            if (response.status === 403 && !isLoaded) {
              return null;
            }
            throw await handleError(response);
          }
          return response.json();
        } catch (error) {
          throw await handleError(error);
        }
      },
      staleTime: 5 * 60 * 1000,
      gcTime: 30 * 60 * 1000,
      retry: (failureCount, error) => {
        if (error instanceof Error && "status" in error && error.status === 403 && !isLoaded) {
          return failureCount < 3;
        }
        return failureCount < 2;
      },
      enabled: isLoaded && isSignedIn
    });

  const useDeactivateUser = () =>
    useMutation({
      mutationFn: async (userId: number) => {
        try {
          const response = await fetch(`/api/users/${userId}`, {
            method: "DELETE"
          });
          if (!response.ok) {
            throw await handleError(response);
          }
          return response.json();
        } catch (error) {
          throw await handleError(error);
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["org_users"] });
      }
    });

  const useActivateUser = () =>
    useMutation({
      mutationFn: async (userId: number) => {
        try {
          const response = await fetch(`/api/users/${userId}/activate`, {
            method: "PATCH"
          });
          if (!response.ok) {
            throw await handleError(response);
          }
          return response.json();
        } catch (error) {
          throw await handleError(error);
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["org_users"] });
      }
    });

  const useInviteOrgAdmin = () =>
    useMutation({
      mutationFn: async ({ email, orgId }: { email: string; orgId: number }) => {
        try {
          const response = await fetch("/api/admin-invites/invite-org-admin", {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({ email, orgId })
          });

          const data = await response.json();

          if (!response.ok) {
            throw await handleError(response);
          }
          return data;
        } catch (error) {
          throw await handleError(error);
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["org_users"] });
      }
    });

  return {
    useGetAllUsers,
    useGetOrgUsers,
    useSuperAdmins,
    usePendingSuperAdmins,
    useSetUserRole,
    useUserInfo,
    useDeactivateUser,
    useActivateUser,
    useInviteOrgAdmin
  };
}
