import { Divider, Flex } from "@chakra-ui/react";
import { Organization } from "@shared/domain.models";
import { useNavigate } from "react-router-dom";

import { OrgForm } from "./OrgForm";
import { OrganizationFormData } from "./OrgForm";
import { UnauthorizedErrorMessage } from "./UnauthorizedErrorMessage";

import { PageHeading } from "~/components/PageHeading";
import useAuditLogMutations from "~/hooks/useAuditLogMutations";
import { useDbOrgs } from "~/hooks/useDbOrgs";
import { useDbUsers } from "~/hooks/useDbUsers";

export const OrgCreate = () => {
  const { useCreateOrg, useBulkInviteOrgAdmin } = useDbOrgs();
  const { useLogOrgAction } = useAuditLogMutations();
  const { useUserInfo } = useDbUsers();
  const navigate = useNavigate();

  const createOrgMutation = useCreateOrg();
  const bulkInviteOrgAdmin = useBulkInviteOrgAdmin();
  const logOrgAction = useLogOrgAction();
  const { data: userInfo } = useUserInfo();

  if (!userInfo?.isSuperAdmin) {
    return (
      <Flex w="100%" h="100vh" justify="center" align="center">
        <UnauthorizedErrorMessage error="Forbidden: Must be super admin to create org" />
      </Flex>
    );
  }

  const handleCreate = (data: OrganizationFormData) => {
    const newOrg: Organization = {
      name: data.name,
      domains: [],
      isActive: true,
      serviceStartDate: data.serviceStartDate ? new Date(data.serviceStartDate) : undefined,
      serviceEndDate: data.serviceEndDate ? new Date(data.serviceEndDate) : undefined,
      admins: [],
      id: undefined,
      maxUsers: data.maxUsers
    };
    //get domains as list
    if (typeof data.domains === "string") {
      const arrayDomains = (data.domains as string).split(",").map((domain: string) => domain.trim());
      newOrg.domains = arrayDomains;
    }
    //get emails as list
    const arrayEmails = (data.adminEmails as string).split(",").map((email: string) => email.trim());

    createOrgMutation.mutate(newOrg, {
      onSuccess: (createdOrg) => {
        if (createdOrg.id !== undefined) {
          bulkInviteOrgAdmin.mutate({ orgId: createdOrg.id, emails: arrayEmails });
          logOrgAction.mutate({
            action: "Created organization " + createdOrg.name,
            orgId: createdOrg.id
          });
        }
        navigate("/orgs");
      },
      onError: (error) => {
        console.error("Error creating organization:", error);
      }
    });
  };

  return (
    <Flex direction="column" align="start">
      <PageHeading text="Add Organization" hasBackButton />
      <Divider mb={4} />
      <OrgForm onSubmit={handleCreate} onCancel={() => navigate("/orgs")} />
    </Flex>
  );
};
