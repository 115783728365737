import { Button, HStack, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
interface UnauthorizedErrorProps {
  error: string;
}

export const UnauthorizedErrorMessage = ({ error }: UnauthorizedErrorProps) => {
  const navigate = useNavigate();
  return (
    <HStack>
      <Text>{error}</Text>
      <Button variant="outline" onClick={() => navigate("/")}>
        Home
      </Button>
    </HStack>
  );
};
