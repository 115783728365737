import { useState } from "react";

import {
  ListItem,
  Text,
  Grid,
  Popover,
  PopoverTrigger,
  IconButton,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Button,
  Flex
} from "@chakra-ui/react";
import { UserInfo } from "@shared/domain.models";
import { BsThreeDots } from "react-icons/bs";

import { useAppState } from "~/hooks/useAppState";
import { useDbUsers } from "~/hooks/useDbUsers";

export const AdminItem = ({ admin }: { admin: UserInfo }) => {
  const { userInfo } = useAppState("userInfo");
  const [isOpen, setIsOpen] = useState(false);
  const { useDeactivateUser, useActivateUser } = useDbUsers();
  const deactivateUser = useDeactivateUser();
  const activateUser = useActivateUser();

  const handleAdminStatusToggle = async () => {
    if (userInfo?.id === admin.id) {
      alert("⛔️ You cannot deactivate yourself ⛔️");
      return;
    }

    if (admin.isActive) {
      const confirmDeactivate = window.confirm(
        `Are you sure you want to deactivate ${admin.firstName} ${admin.lastName}?`
      );
      if (confirmDeactivate) {
        try {
          await deactivateUser.mutateAsync(admin.id);
        } catch (error) {
          console.error("Error deactivating admin:", error);
        }
      }
    } else {
      const confirmActivate = window.confirm(`Are you sure you want to activate ${admin.firstName} ${admin.lastName}?`);
      if (confirmActivate) {
        try {
          await activateUser.mutateAsync(admin.id);
        } catch (error) {
          console.error("Error activating admin:", error);
        }
      }
    }
    setIsOpen(false);
  };

  return (
    <ListItem key={admin.id} p={4}>
      <Grid
        templateColumns={{
          base: "repeat(2, 1fr) 40px",
          md: "repeat(4, 1fr) 40px"
        }}
        gap={4}
        alignItems="center"
      >
        <Text>
          {admin.firstName} {admin.lastName}
        </Text>
        <Text display={{ base: "none", md: "block" }}>{admin.emailAddress}</Text>
        {admin.isActive ? <Text>Active</Text> : <Text color="red.500">Inactive</Text>}
        <Text display={{ base: "none", md: "block" }}>
          {admin.createdAt instanceof Date
            ? admin.createdAt.toLocaleDateString()
            : new Date(admin.createdAt).toLocaleDateString()}
        </Text>
        <Popover isOpen={isOpen} onOpen={() => setIsOpen(true)} onClose={() => setIsOpen(false)}>
          <PopoverTrigger>
            <IconButton aria-label="Options" margin="auto" icon={<BsThreeDots />} variant="plain" />
          </PopoverTrigger>
          <Portal>
            <PopoverContent maxWidth="200px">
              <PopoverArrow />
              <PopoverBody>
                <Flex direction="column" alignItems="flex-start">
                  <Button colorScheme="red" size="sm" variant="ghost" onClick={handleAdminStatusToggle}>
                    {admin.isActive ? (
                      <Text color="red.500">Deactivate Admin</Text>
                    ) : (
                      <Text color="green.500">Activate Admin</Text>
                    )}
                  </Button>
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      </Grid>
    </ListItem>
  );
};

export default AdminItem;
