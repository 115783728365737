// import { switchAnatomy } from "@chakra-ui/anatomy";
import { extendTheme } from "@chakra-ui/react";

import { config } from "./config";

// const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(switchAnatomy.keys);

export const theme = extendTheme({
  colors: {
    brand: {
      primary: "#4E4B7E",
      secondary: "#00ff00",
      cta: "#0083E2"
    },
    pastels: {
      50: "#FFD1DC", // Light Pink
      100: "#E0BBE4", // Light Purple
      200: "#BFE3B4", // Light Green
      300: "#F1C0E8", // Pastel Purple
      400: "#FEE4C3", // Light Orange
      500: "#C2F2D0", // Light Mint
      600: "#D0F0FD" // Light Blue
    }
  },
  textStyles: {
    body: {
      fontFamily: "Inter Variable",
      fontWeight: "400",
      color: "black",
      fontSize: ".90rem",
      lineHeight: "tall"
    }
  },
  fonts: {
    heading: "Quicksand Variable, sans-serif",
    body: "Inter Variable, sans-serif"
  },
  components: {
    Link: {
      baseStyle: {
        color: "brand.primary",
        _hover: {
          textDecoration: "underline"
        }
      },
      variants: {
        customVariant: {
          color: "green.600",
          fontWeight: "bold"
        }
      }
    },
    Button: {
      variants: {
        cta: {
          bg: "brand.cta",
          _hover: { bg: "#026DBD" },
          color: "white",
          fontWeight: "600"
        },
        secondary: {
          color: "gray.700",
          _hover: { bg: "gray.100" },
          bg: "white",
          border: "1px",
          fontWeight: "600",
          borderColor: "gray.400"
        },
        danger: {
          bg: "#D20404",
          _hover: { bg: "#B50303" },
          color: "white",
          fontWeight: "600"
        },
        outline: {
          color: "brand.primary",
          border: "1px solid",
          borderColor: "brand.primary",
          borderRadius: "3",
          _hover: { bg: "gray.100" }
        },
        ghost: {
          color: "brand.primary",
          _hover: { bg: "gray.100" }
        }
      }
    },
    Container: {
      sizes: {
        sm: {
          maxWidth: "640px"
        },
        md: {
          maxWidth: "960px"
        },
        lg: {
          maxWidth: "1280px"
        },
        xl: {
          maxWidth: "1600px"
        }
      }
    },
    Select: {
      variants: {
        outline: {
          field: {
            borderColor: "gray.300",
            borderWidth: "1px",
            _focus: {
              borderColor: "blue.500",
              boxShadow: "0 0 0 1px #3182ce"
            }
          }
        }
      }
    },
    Input: {
      variants: {
        outline: {
          field: {
            borderColor: "gray.300",
            borderWidth: "1px",
            _focus: {
              borderColor: "blue.500",
              boxShadow: "0 0 0 1px #3182ce"
            },
            _invalid: {
              borderColor: "red.500",
              _focus: {
                borderColor: "red.500",
                boxShadow: "0 0 0 2px #BB1313"
              }
            }
          }
        }
      }
    },
    Heading: {
      baseStyle: {
        color: "brand.primary"
      }
    }
    // Switch: {        TODO: https://v2.chakra-ui.com/docs/components/switch/theming
    //   baseStyle: {     TODO: https://www.npmjs.com/package/@chakra-ui/anatomy
    //     track: {
    //       bg: "#ccc"
    //     },
    //     _checked: {
    //       track: {
    //         bg: "brand.primary"
    //       },
    //       thumb: {
    //         bg: "white"
    //       }
    //     }
    //   }
    // }
  },
  config
});

export const ThemeColors = {
  brand: {
    primary: "brand.primary",
    primaryValue: theme.colors.brand.primary,
    cta: "brand.cta"
  }
};
