import type { PathRouteProps } from "react-router-dom";

import HomePage from "../pages/home/HomePage";

import { ActivityLog } from "~/components/ActivityLog";
import { OrgCreate } from "~/components/OrgCreate";
import { OrgEdit } from "~/components/OrgEdit";
import OrgManage from "~/components/OrgManage";
import AccountPage from "~/pages/AccountPage";
import AdminListPage from "~/pages/AdminListPage";
import AdminPage from "~/pages/AdminPage";
import AnotherPage from "~/pages/AnotherPage";
import OrgListPage from "~/pages/OrgListPage";
import SuperAdminListPage from "~/pages/SuperAdminListPage";

interface Props extends PathRouteProps {
  bodyPadding?: number[];
  bgColor?: string;
}

export const routes: Array<Props> = [];

export const privateRoutes: Array<Props> = [
  {
    path: "/",
    element: <HomePage />
  },
  {
    path: "/account",
    element: <AccountPage />
  },
  {
    path: "/another",
    element: <AnotherPage />
  },
  {
    path: "/superadmins",
    element: <SuperAdminListPage />
  },
  {
    path: "/admin",
    element: <AdminPage />
  },
  {
    path: "/orgs",
    element: <OrgListPage />
  },
  {
    path: "/orgs/:id",
    element: <OrgEdit />
  },
  {
    path: "/orgs/new",
    element: <OrgCreate />
  },
  {
    path: "/orgs/:id/manage",
    element: <OrgManage />
  },
  {
    path: "/orgs/:id/admins",
    element: <AdminListPage />
  },
  {
    path: "orgs/:id/activity",
    element: <ActivityLog />
  }
];
