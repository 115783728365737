import { Organization, UserInfo } from "@shared/domain.models";
import ExcelJs from "exceljs";

interface PendingInvite {
  email: string;
  createdAt: Date;
  expiresAt: Date;
}

type AdminWithStatus = UserInfo | (PendingInvite & { emailAddress: string; orgId?: number; orgName?: string });

export interface HomePageAnalytics {
  totalUsers: number;
  totalSessionsCompleted: number;
  stressChangeOverall: number;
  usersLastDays: number;
  sessionsCompletedLastDays: number;
  stressChangeLastDays: number;
  startingStressLastDays: number;
  newUsersLastDays: number;
  maxDays: number;
}

async function createAndDownloadExcelFile(
  worksheetName: string,
  columns: Partial<ExcelJs.Column>[],
  data: Record<string, string | number | boolean | undefined>[],
  filename: string
) {
  const workbook = new ExcelJs.Workbook();
  const worksheet = workbook.addWorksheet(worksheetName);
  worksheet.columns = columns;
  data.forEach((row) => {
    worksheet.addRow(row);
  });

  worksheet.getRow(1).font = { bold: true };

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = `${filename}.xlsx`;
  link.click();
  window.URL.revokeObjectURL(link.href);
}

function formatDate(date: Date | undefined): string {
  return date ? new Date(date).toISOString().split("T")[0] : "";
}

export async function exportSuperAdminsToExcel(admins: AdminWithStatus[], filename: string) {
  const columns: Partial<ExcelJs.Column>[] = [
    { header: "ID", key: "id", width: 10 },
    { header: "First Name", key: "firstName", width: 20 },
    { header: "Last Name", key: "lastName", width: 20 },
    { header: "Email Address", key: "emailAddress", width: 32 },
    { header: "Created At", key: "createdAt", width: 20 },
    { header: "Status", key: "status", width: 20 }
  ];

  const rows: Record<string, string | number | boolean | undefined>[] = admins.map((admin) => {
    const isPendingInvite = !("firstName" in admin);
    return {
      id: isPendingInvite ? "" : admin.id,
      firstName: isPendingInvite ? "" : (admin as UserInfo).firstName,
      lastName: isPendingInvite ? "" : (admin as UserInfo).lastName,
      emailAddress: admin.emailAddress,
      createdAt: formatDate(admin.createdAt),
      status: isPendingInvite ? "Invited" : (admin as UserInfo).isActive ? "Active" : "Inactive"
    };
  });

  await createAndDownloadExcelFile("Super Admins", columns, rows, filename);
}

export async function exportOrgAdminsToExcel(admins: AdminWithStatus[], filename: string) {
  const columns: Partial<ExcelJs.Column>[] = [
    { header: "ID", key: "id", width: 10 },
    { header: "First Name", key: "firstName", width: 20 },
    { header: "Last Name", key: "lastName", width: 20 },
    { header: "Email Address", key: "emailAddress", width: 32 },
    { header: "Org ID", key: "orgId", width: 20 },
    { header: "Org Name", key: "orgName", width: 20 },
    { header: "Created At", key: "createdAt", width: 20 },
    { header: "Status", key: "status", width: 20 }
  ];

  const rows: Record<string, string | number | boolean | undefined>[] = admins.map((admin) => {
    const isPendingInvite = !("firstName" in admin);
    return {
      id: isPendingInvite ? "" : admin.id,
      firstName: isPendingInvite ? "" : (admin as UserInfo).firstName,
      lastName: isPendingInvite ? "" : (admin as UserInfo).lastName,
      emailAddress: admin.emailAddress,
      orgId: admin.orgId,
      orgName: admin.orgName,
      createdAt: formatDate(admin.createdAt),
      status: isPendingInvite ? "Invited" : (admin as UserInfo).isActive ? "Active" : "Inactive"
    };
  });

  await createAndDownloadExcelFile("Org Admins", columns, rows, filename);
}

export async function exportOrgListToExcel(orgs: Organization[], filename: string) {
  const columns: Partial<ExcelJs.Column>[] = [
    { header: "ID", key: "id", width: 10 },
    { header: "Name", key: "name", width: 32 },
    { header: "Domains", key: "domains", width: 32 },
    { header: "Created At", key: "createdAt", width: 20 },
    { header: "Service Start Date", key: "serviceStartDate", width: 20 },
    { header: "Service End Date", key: "serviceEndDate", width: 20 },
    { header: "Admins", key: "Admins", width: 20 }
  ];

  const rows: Record<string, string | number | boolean | undefined>[] = [];
  orgs.forEach((org) => {
    rows.push({
      id: org.id,
      name: org.name,
      domains: org.domains.join(", "),
      createdAt: org.createdAt ? new Date(org.createdAt).toISOString().split("T")[0] : "",
      serviceStartDate: org.serviceStartDate ? new Date(org.serviceStartDate).toISOString().split("T")[0] : "",
      serviceEndDate: org.serviceEndDate ? new Date(org.serviceEndDate).toISOString().split("T")[0] : "",
      Admins: org.admins.join(", ")
    });
  });

  await createAndDownloadExcelFile("Organizations", columns, rows, filename);
}

export async function exportHomePageAnalytics(
  analytics7Days: HomePageAnalytics,
  analytics30Days: HomePageAnalytics | null,
  analytics60Days: HomePageAnalytics | null,
  analytics90Days: HomePageAnalytics | null,
  filename: string
) {
  const columns: Partial<ExcelJs.Column>[] = [
    { header: "Category", key: "category", width: 25 },
    { header: "All Time", key: "allTime", width: 15 },
    { header: "Last 7 Days", key: "last7Days", width: 15 },
    ...(analytics30Days ? [{ header: "Last 30 Days", key: "last30Days", width: 15 }] : []),
    ...(analytics60Days ? [{ header: "Last 60 Days", key: "last60Days", width: 15 }] : []),
    ...(analytics90Days ? [{ header: "Last 90 Days", key: "last90Days", width: 15 }] : [])
  ];

  const rows = [
    {
      category: "New Users",
      allTime: analytics7Days.totalUsers,
      last7Days: analytics7Days.newUsersLastDays,
      ...(analytics30Days && { last30Days: analytics30Days.newUsersLastDays }),
      ...(analytics60Days && { last60Days: analytics60Days.newUsersLastDays }),
      ...(analytics90Days && { last90Days: analytics90Days.newUsersLastDays })
    },
    {
      category: "Sessions Completed",
      allTime: analytics7Days.totalSessionsCompleted,
      last7Days: analytics7Days.sessionsCompletedLastDays,
      ...(analytics30Days && { last30Days: analytics30Days.sessionsCompletedLastDays }),
      ...(analytics60Days && { last60Days: analytics60Days.sessionsCompletedLastDays }),
      ...(analytics90Days && { last90Days: analytics90Days.sessionsCompletedLastDays })
    },
    {
      category: "Average Stress Decreased",
      allTime: -analytics7Days.stressChangeOverall,
      last7Days: -analytics7Days.stressChangeLastDays,
      ...(analytics30Days && { last30Days: -analytics30Days.stressChangeLastDays }),
      ...(analytics60Days && { last60Days: -analytics60Days.stressChangeLastDays }),
      ...(analytics90Days && { last90Days: -analytics90Days.stressChangeLastDays })
    },
    {
      category: "Average Starting Stress",
      allTime: "-",
      last7Days: analytics7Days.startingStressLastDays,
      ...(analytics30Days && { last30Days: analytics30Days.startingStressLastDays }),
      ...(analytics60Days && { last60Days: analytics60Days.startingStressLastDays }),
      ...(analytics90Days && { last90Days: analytics90Days.startingStressLastDays })
    },
    {
      category: "Active Users",
      allTime: "-",
      last7Days: analytics7Days.usersLastDays,
      ...(analytics30Days && { last30Days: analytics30Days.usersLastDays }),
      ...(analytics60Days && { last60Days: analytics60Days.usersLastDays }),
      ...(analytics90Days && { last90Days: analytics90Days.usersLastDays })
    },
    {
      category: "Total History (Days)",
      allTime: analytics7Days.maxDays,
      last7Days: "-",
      ...(analytics30Days && { last30Days: "-" }),
      ...(analytics60Days && { last60Days: "-" }),
      ...(analytics90Days && { last90Days: "-" })
    }
  ];

  await createAndDownloadExcelFile("Analytics", columns, rows, filename);
}
