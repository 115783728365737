import { ReactNode } from "react";

import { Icon } from "@chakra-ui/icons";
import { UserRole } from "@shared/domain.models";
import { FaUserShield } from "react-icons/fa6";
import { HiUserGroup } from "react-icons/hi2";
import { IoHome } from "react-icons/io5";

import { AppConfig } from "~/services/app-config-service";

export interface MenuLink {
  label: string;
  path: string;
  target?: string;
  icon?: ReactNode;
  userRoles: UserRole[];
}

export const TopMenuLinks: MenuLink[] = [
  // { label: "Team", path: "https://www/our-team", target: "_blank", userRoles: allRoles }
];

export let SideMenuLinks: MenuLink[] = [
  { label: "Home", path: "/", icon: <Icon as={IoHome} />, userRoles: [UserRole.SuperAdmin] },
  { label: "Super Admins", path: "/superadmins", icon: <Icon as={FaUserShield} />, userRoles: [UserRole.SuperAdmin] },
  { label: "Organizations", path: "/orgs", icon: <Icon as={HiUserGroup} />, userRoles: [UserRole.SuperAdmin] }
];

// TODO: Remove this once we go live with profile-driven conversations
if (AppConfig.shouldHideWipFeatures) {
  SideMenuLinks = SideMenuLinks.filter((link) => link.label !== "Profile");
}
