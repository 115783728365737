import { Box, Text, Button, Divider, Flex, Icon, useDisclosure, Tag, HStack } from "@chakra-ui/react";
import { Organization } from "@shared/domain.models";
import { FaBoxArchive } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";

import { ConfirmationDialog } from "./ConfirmationDialog";
import { OrganizationFormData, OrgForm } from "./OrgForm";
import { UnauthorizedErrorMessage } from "./UnauthorizedErrorMessage";

import { PageHeading } from "~/components/PageHeading";
import useAuditLogMutations from "~/hooks/useAuditLogMutations";
import { useDbOrgs } from "~/hooks/useDbOrgs";
import { useDbUsers } from "~/hooks/useDbUsers";

export const OrgEdit = () => {
  const { id } = useParams<{ id: string }>();
  const { useGetOrgById, useUpdateOrg } = useDbOrgs();
  const { useLogOrgAction } = useAuditLogMutations();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { useUserInfo } = useDbUsers();
  const { data: userInfo } = useUserInfo();

  if (!userInfo?.isSuperAdmin) {
    return (
      <Flex w="100%" h="100vh" justify="center" align="center">
        <UnauthorizedErrorMessage error="Forbidden: Must be super admin to edit org" />
      </Flex>
    );
  }

  const { data: org, isLoading, isError } = useGetOrgById(Number(id));
  const updateOrgMutation = useUpdateOrg();
  const logOrgAction = useLogOrgAction();

  //Convert domain Organization -> OrganizationFormData
  function mapOrgToFormData(org: Organization): OrganizationFormData {
    return {
      id: org.id,
      name: org.name,
      // Convert string[] domains to a comma-separated string
      domains: org.domains?.join(", ") || "",
      serviceStartDate: org.serviceStartDate || "",
      serviceEndDate: org.serviceEndDate || "",
      adminEmails: org.admins
        ?.map((admin) => admin.emailAddress)
        .filter(Boolean)
        .join(", "),
      maxUsers: org.maxUsers
    };
  }

  // Convert form data -> domain Organization (or partial) before updating
  function mapFormDataToOrg(formData: OrganizationFormData, existingOrg: Organization): Organization {
    return {
      ...existingOrg,
      name: formData.name,
      domains:
        formData.domains
          ?.split(",")
          .map((d) => d.trim())
          .filter(Boolean) || [],
      serviceStartDate: formData.serviceStartDate ? new Date(formData.serviceStartDate) : undefined,
      serviceEndDate: formData.serviceEndDate ? new Date(formData.serviceEndDate) : undefined,
      maxUsers: formData.maxUsers
    };
  }

  const handleUpdate = (formData: OrganizationFormData) => {
    if (!org) {
      console.error("No org data found to update");
      return;
    }

    // Convert form data -> domain object for the update
    const updatedOrg = mapFormDataToOrg(formData, org);
    if (!org.id) {
      console.error("Organization ID is not available");
      return;
    }

    // Track individual changes
    const changes: { field: string; value: string }[] = [];
    if (updatedOrg.name !== org.name) {
      changes.push({ field: "name", value: `Changed name from "${org.name}" to "${updatedOrg.name}"` });
    }
    if (updatedOrg.maxUsers !== org.maxUsers) {
      changes.push({ field: "maxUsers", value: `Changed max users from ${org.maxUsers} to ${updatedOrg.maxUsers}` });
    }
    if (JSON.stringify(updatedOrg.domains) !== JSON.stringify(org.domains)) {
      changes.push({
        field: "domains",
        value: `Changed domains from [${org.domains.join(", ")}] to [${updatedOrg.domains.join(", ")}]`
      });
    }

    // Convert dates to strings for comparison
    const oldStartDate = org.serviceStartDate ? new Date(org.serviceStartDate).toISOString().split("T")[0] : "";
    const newStartDate = updatedOrg.serviceStartDate
      ? new Date(updatedOrg.serviceStartDate).toISOString().split("T")[0]
      : "";
    const oldEndDate = org.serviceEndDate ? new Date(org.serviceEndDate).toISOString().split("T")[0] : "";
    const newEndDate = updatedOrg.serviceEndDate ? new Date(updatedOrg.serviceEndDate).toISOString().split("T")[0] : "";

    if (oldStartDate !== newStartDate) {
      changes.push({
        field: "serviceStartDate",
        value: `Changed service start date from ${oldStartDate || "none"} to ${newStartDate || "none"}`
      });
    }
    if (oldEndDate !== newEndDate) {
      changes.push({
        field: "serviceEndDate",
        value: `Changed service end date from ${oldEndDate || "none"} to ${newEndDate || "none"}`
      });
    }

    // Call your updateOrgMutation
    updateOrgMutation.mutate(
      { orgId: org.id, updatedOrg },
      {
        onSuccess: () => {
          // Log each change separately
          changes.forEach((change) => {
            logOrgAction.mutate({
              action: change.value,
              orgId: org.id!
            });
          });
          navigate("/orgs");
        },
        onError: (error) => {
          console.error("Error updating organization:", error);
        }
      }
    );
  };

  const handleStatusToggle = () => {
    if (!org) {
      console.error("Organization data is not available");
      return;
    }

    // If org is active, show confirmation dialog first
    if (org.isActive) {
      onOpen();
      return;
    }

    // For activation, proceed directly
    performStatusToggle();
  };

  const performStatusToggle = () => {
    if (!org) return;

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    const updatedOrg = {
      ...org,
      isActive: !org.isActive,
      serviceEndDate: !org.isActive ? today : yesterday
    };

    updateOrgMutation.mutate(
      { orgId: org.id!, updatedOrg },
      {
        onSuccess: () => {
          if (org.id !== undefined) {
            logOrgAction.mutate({
              action: org.isActive ? "Deactivated organization " + org.name : "Activated organization " + org.name,
              orgId: org.id
            });
          }
          // Only navigate away on deactivation
          if (org.isActive) {
            navigate("/orgs");
          }
          onClose();
        },
        onError: (error) => {
          console.error(`Error ${org.isActive ? "deactivating" : "activating"} organization:`, error);
          onClose();
        }
      }
    );
  };

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  if (isError || !org) {
    return (
      <Box textAlign="center" mt={10}>
        <Text fontSize="2xl" fontWeight="bold">
          Error
        </Text>
        <Text>The organization with ID {id} could not be found.</Text>
        <Button mt={4} onClick={() => navigate("/orgs")}>
          Back to Organizations
        </Button>
      </Box>
    );
  }

  const defaultFormData: OrganizationFormData = mapOrgToFormData(org);

  return (
    <Flex direction="column" align="start">
      <PageHeading text="Edit Organization" hasBackButton>
        <Flex gap={8} align="center">
          <HStack>
            <Text>Org Status:</Text>
            <Tag colorScheme={org.isActive ? "green" : "red"}>{org.isActive ? "Active" : "Inactive"}</Tag>
          </HStack>

          <Button onClick={handleStatusToggle} variant="outline" gap={2}>
            <Icon as={FaBoxArchive} />
            {org.isActive ? "Deactivate" : "Activate"}
          </Button>
        </Flex>
      </PageHeading>

      <Divider mb={4} />
      <OrgForm
        defaultValues={defaultFormData}
        onSubmit={handleUpdate}
        onCancel={() => navigate("/orgs")}
        onDelete={handleStatusToggle}
        inputProps={{ isDisabled: !org.isActive }}
      />

      <ConfirmationDialog
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={performStatusToggle}
        title="Deactivate Organization"
        body={`Are you sure you want to deactivate ${org.name}? This will set the service end date to today and mark the organization as inactive.`}
        confirmText="Deactivate"
        confirmColorScheme="red"
      />
    </Flex>
  );
};
