import { IconButton } from "@chakra-ui/icons";
import {
  Box,
  ListItem,
  Text,
  Icon,
  HStack,
  Grid,
  Popover,
  Portal,
  PopoverArrow,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Button,
  Tooltip
} from "@chakra-ui/react";
import { Organization } from "@shared/domain.models";
import { BsThreeDots } from "react-icons/bs";
import { CgArrowTopRight } from "react-icons/cg";
import { FaExclamationCircle } from "react-icons/fa";
import { useNavigate, Link } from "react-router-dom";

import { useDbUsers } from "~/hooks/useDbUsers";
import { ThemeColors } from "~/styles/theme";

export const OrgItem = ({ org }: { org: Organization }) => {
  const navigate = useNavigate();
  const { useUserInfo } = useDbUsers();
  const { data: user } = useUserInfo();

  const getExpirationInfo = (date: Date | undefined | null) => {
    if (!date) return { isExpired: false, isExpiringSoon: false };

    const expirationDate = new Date(date);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const daysUntilExpiration = Math.ceil((expirationDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));

    return {
      isExpired: expirationDate < today,
      isExpiringSoon: daysUntilExpiration > 0 && daysUntilExpiration <= 60
    };
  };

  const { isExpired, isExpiringSoon } = getExpirationInfo(org.serviceEndDate);

  // Determine the link based on user role
  const linkTo = user?.isSuperAdmin ? `/?orgId=${org.id}` : "/";

  return (
    <ListItem key={org.id} p={4}>
      <Grid
        templateColumns={{
          base: "2fr 1fr 40px",
          md: "repeat(5, 1fr) 40px"
        }}
        gap={4}
        alignItems="center"
        borderRadius="3"
      >
        {/* Organization Name */}
        <Link to={linkTo}>
          <HStack gap={0}>
            <Text fontWeight="bold" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
              {org.name}
            </Text>
            <Icon>
              <CgArrowTopRight size="2em" />
            </Icon>
          </HStack>
        </Link>

        {/* Email Domains (hidden on mobile) */}
        <Text display={{ base: "none", md: "block" }} whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
          {org.domains.join(", ")}
        </Text>

        {/* Status: Always visible */}
        {org.isActive ? <Text>Active</Text> : <Text color="red.500">Inactive</Text>}

        {/* Start Date (hidden on mobile) */}
        <Text display={{ base: "none", md: "block" }}>
          {org.serviceStartDate ? new Date(org.serviceStartDate).toLocaleDateString() : "N/A"}
        </Text>

        {/* Expiration Date (hidden on mobile) */}
        <HStack display={{ base: "none", md: "flex" }} spacing={2}>
          <Text color={isExpired ? "red.500" : undefined}>
            {org.serviceEndDate ? new Date(org.serviceEndDate).toLocaleDateString() : "N/A"}
          </Text>
          {isExpiringSoon && !isExpired && (
            <Tooltip label="Organization will expire within 60 days" hasArrow>
              <Box>
                <Icon as={FaExclamationCircle} color="orange.500" />
              </Box>
            </Tooltip>
          )}
        </HStack>

        {/* Popover Menu */}
        <Popover>
          <PopoverTrigger>
            <IconButton aria-label="Options" margin="auto" icon={<BsThreeDots />} variant="plain" />
          </PopoverTrigger>
          <Portal>
            <PopoverContent maxWidth="200px">
              <PopoverArrow />
              <PopoverBody>
                {/* MOBILE VIEW: "View Details" button only */}
                <Box display={{ base: "block", md: "none" }}>
                  <Button
                    onClick={() => navigate(`/orgs/${org.id}`)}
                    colorScheme={ThemeColors.brand.primary}
                    size="sm"
                    variant="ghost"
                  >
                    View Details
                  </Button>
                </Box>
                {/* DESKTOP VIEW: Original Buttons */}
                <Box display={{ base: "none", md: "block" }}>
                  <Button
                    onClick={() => navigate(`/orgs/${org.id}`)}
                    colorScheme={ThemeColors.brand.primary}
                    size="sm"
                    variant="ghost"
                  >
                    Manage Organization
                  </Button>
                </Box>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      </Grid>
    </ListItem>
  );
};
