import { Flex, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, VStack } from "@chakra-ui/react";
import { AuditLog } from "@shared/domain.models";

import { LogRow } from "~/components/LogRow";
import { PageHeading } from "~/components/PageHeading";
import { useAuditLogs } from "~/hooks/useAuditLogs";
import { useDbUsers } from "~/hooks/useDbUsers";
import { ThemeColors } from "~/styles/theme";

const formatLogBody = (log: AuditLog): string => {
  return `${log.action}`;
};

export const ActivityLog = () => {
  const { useUserInfo } = useDbUsers();
  const { data: userInfo } = useUserInfo();

  const { logs, isLoading, error, organizationLogs, adminLogs, appVersionLogs } = useAuditLogs({
    orgId: userInfo?.isSuperAdmin ? undefined : userInfo?.orgId
  });

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const renderLogList = (filteredLogs: AuditLog[] = []) => (
    <VStack spacing={4} align="start" w="100%">
      {filteredLogs.map((log, index) => (
        <LogRow
          key={index}
          firstName={log.user?.firstName ?? ""}
          lastName={log.user?.lastName ?? ""}
          emailAddress={log.user?.emailAddress ?? ""}
          profileImageUrl={undefined}
          date={new Date(log.created_at ?? "")}
          body={formatLogBody(log)}
          category={log.category}
          isSuperAdmin={log.user?.isSuperAdmin}
        />
      ))}
    </VStack>
  );

  return (
    <Flex direction="column" align="center" w="100%">
      <PageHeading text="Activity Log" hasBackButton />
      <Tabs w="100%">
        <TabList>
          <Tab _selected={{ fontWeight: "bold", color: "black" }}>All</Tab>
          <Tab _selected={{ fontWeight: "bold", color: "black" }}>Admin</Tab>
          <Tab _selected={{ fontWeight: "bold", color: "black" }}>Organization</Tab>
        </TabList>

        <TabIndicator mt="-1.5px" height="3px" bg={ThemeColors.brand.primary} borderRadius="2px" />
        <TabPanels p={8}>
          <TabPanel>
            {renderLogList(logs)}
          </TabPanel>
          <TabPanel>
            {renderLogList(adminLogs)}
          </TabPanel>
          <TabPanel>
            {renderLogList(organizationLogs)}
          </TabPanel>
          <TabPanel>
            {renderLogList(appVersionLogs)}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};
