import { AuditLog } from "@shared/domain.models";
import { useMutation, UseMutationResult } from "@tanstack/react-query";

import { useDbUsers } from "./useDbUsers";

import { queryClient } from "~/App";

interface LogActionParams {
  action: string;
  orgId: number;
}

const useAuditLogMutations = () => {
  const { useUserInfo } = useDbUsers();
  const { data: userInfo } = useUserInfo();

  const createLogMutation = (category: string) =>
    useMutation<AuditLog, Error, LogActionParams>({
      mutationFn: async ({ action, orgId }) => {
        if (!userInfo?.id) {
          throw new Error("User not logged in, cannot log action");
        }
        const response = await fetch("/api/audit-logs", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            userId: userInfo.id,
            orgId,
            category,
            action
          })
        });
        if (!response.ok) {
          throw new Error(`Failed to log ${category.toLowerCase()} action`);
        }
        return response.json();
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["audit-logs"] });
      }
    });

  const useLogAdminAction = (): UseMutationResult<AuditLog, Error, LogActionParams> => createLogMutation("Admins");

  const useLogOrgAction = (): UseMutationResult<AuditLog, Error, LogActionParams> => createLogMutation("Organization");

  const useLogAppVersionAction = (): UseMutationResult<AuditLog, Error, LogActionParams> =>
    createLogMutation("AppVersion");

  return {
    useLogAdminAction,
    useLogOrgAction,
    useLogAppVersionAction
  };
};

export default useAuditLogMutations;
