import { useNavigate } from "react-router-dom";

import { handleApiError } from "~/utils/api-error-handler";

export const useApiErrorHandler = () => {
  const navigate = useNavigate();

  return async (error: unknown) => {
    return handleApiError(error, navigate);
  };
};
