import { Box, Text, Heading, Flex } from "@chakra-ui/react";
import { IconType } from "react-icons";
interface TrendPannelProps {
  icon: IconType;
  header: string;
  body: string;
  percentChange: number;
}
export const TrendPannel = ({ icon, header, body, percentChange }: TrendPannelProps) => {
  return (
    <Flex
      p={{ base: 2, md: 4 }}
      bg="none"
      borderRadius="10"
      gap={2}
      direction={"column"}
      border={"1px solid LightGray"}
      width="100%"
    >
      <Box as={icon} boxSize={5} />
      <Box>
        {header && <Text>{header}</Text>}

        {body && (
          <Heading size="lg" color="Black">
            {body}
          </Heading>
        )}

        {percentChange !== 0 && (
          <Text fontSize="sm" color={percentChange > 0 ? "green.500" : "gray.500"}>
            {percentChange > 0 ? "+" + percentChange : percentChange}%
          </Text>
        )}
      </Box>
    </Flex>
  );
};
