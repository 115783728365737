import { Box, Divider, Flex, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { OrgForm } from "./OrgForm";

import { AdminList } from "~/components/AdminList";
import { ExportButton } from "~/components/ExportButton";
import { InviteButton } from "~/components/InviteButton";
import { PageHeading } from "~/components/PageHeading";
import { useDbOrgs } from "~/hooks/useDbOrgs";
import { useDbUsers } from "~/hooks/useDbUsers";
import { exportOrgAdminsToExcel } from "~/services/local-export-service";
import { cleanFilename } from "~/utils/filename-util";

const OrgManage = () => {
  const { id } = useParams<{ id: string }>();
  const orgId = Number(id);
  const { useGetOrgById } = useDbOrgs();
  const { useGetOrgUsers } = useDbUsers();

  // Fetch the organization
  const { data: org, isLoading: orgLoading, isError: orgError } = useGetOrgById(orgId);

  // Fetch org users
  const { data: orgUsers, isLoading: orgUsersLoading, isError: orgUsersError } = useGetOrgUsers(orgId);

  const mergedOrg = org && orgUsers ? { ...org, admins: orgUsers.users } : null;
  const formData = mergedOrg ? { ...mergedOrg, domains: mergedOrg.domains.join(",") } : null;
  const exportFileName = mergedOrg ? `WWA_${mergedOrg.name}_Admins` : "WWA_Admins";

  // Combine active users and pending invites for export
  const allAdmins = [
    ...(orgUsers?.users || []),
    ...(orgUsers?.pendingInvites || []).map((invite) => ({
      ...invite,
      emailAddress: invite.email,
      orgId,
      orgName: org?.name || ""
    }))
  ];

  if (orgError || orgUsersError) return <Box>Error loading org.</Box>;

  if (orgLoading || orgUsersLoading || !mergedOrg) return <Box>Loading...</Box>;

  return (
    <Flex direction="column" align="start" w="100%">
      <PageHeading text={`Manage Organization: "${mergedOrg.name}"`} hasBackButton>
        <Flex gap={4}>
          <InviteButton orgId={orgId} />
          <ExportButton onClick={() => exportOrgAdminsToExcel(allAdmins, cleanFilename(exportFileName))} />
        </Flex>
      </PageHeading>

      <Divider my={4} />
      <OrgForm
        defaultValues={formData || undefined}
        onSubmit={() => {}}
        onCancel={() => {}}
        inputProps={{ isDisabled: true }}
      />
      <Flex
        direction={["column", "row"]}
        align={["flex-start", "center"]}
        ml={{ base: 0, md: 44 }}
        gap={4}
        flexWrap={{ base: "wrap", md: "nowrap" }}
      >
        <Text minW="200px" whiteSpace="nowrap">
          Organization Admin(s)
        </Text>
        <Flex gap={4}>
          <InviteButton orgId={orgId} />
          <ExportButton onClick={() => exportOrgAdminsToExcel(allAdmins, cleanFilename(exportFileName))} />
        </Flex>
      </Flex>
      <Divider my={4} />
      <Box px={{ base: 0, md: 44 }} w="100%">
        <AdminList admins={mergedOrg.admins} pendingInvites={orgUsers?.pendingInvites} />
      </Box>
    </Flex>
  );
};

export default OrgManage;
