import { Button, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { HiChevronDown } from "react-icons/hi2";

import { ThemeColors } from "~/styles/theme";

export const ButtonMenu = ({ value, setValue, options, size = "md" }: MenuItemProps) => {
  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<HiChevronDown size={20} />}
        borderBottom="2px solid"
        borderTop="none"
        borderLeft="none"
        borderRight="none"
        borderRadius={0}
        size={size}
        color={ThemeColors.brand.primary}
        background={"transparent"}
      >
        {value}
      </MenuButton>
      <MenuList>
        {options.map((option) => (
          <MenuItem onClick={() => setValue(option)} key={option}>
            {option}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

interface MenuItemProps {
  value: string;
  setValue: (value: string) => void;
  options: string[];
  size?: "sm" | "md" | "lg";
}
