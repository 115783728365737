import { useNavigate } from "react-router-dom";

export class ApiError extends Error {
  status: number;
  originalError?: unknown;

  constructor(message: string, status: number, originalError?: unknown) {
    super(message);
    this.status = status;
    this.name = "ApiError";
    this.originalError = originalError;
  }
}

export const handleApiError = async (error: unknown, navigate?: ReturnType<typeof useNavigate>): Promise<ApiError> => {
  if (error instanceof ApiError) {
    if (error.status === 403 && navigate) {
      navigate("/403");
    }
    return error;
  }

  // Handle fetch errors
  if (error instanceof Response) {
    try {
      const data = await error.json();
      const message = data.error || error.statusText;
      const apiError = new ApiError(message, error.status, error);
      if (error.status === 403 && navigate) {
        navigate("/403");
      }
      return apiError;
    } catch {
      // If JSON parsing fails, use status text
      const apiError = new ApiError(error.statusText, error.status, error);
      if (error.status === 403 && navigate) {
        navigate("/403");
      }
      return apiError;
    }
  }

  // Handle axios errors
  if (error && typeof error === "object" && "response" in error) {
    const axiosError = error as {
      response?: {
        status: number;
        statusText: string;
        data?: { error?: string; message?: string };
      };
    };
    if (axiosError.response) {
      const message =
        axiosError.response.data?.error || axiosError.response.data?.message || axiosError.response.statusText;
      const apiError = new ApiError(message, axiosError.response.status, error);
      if (axiosError.response.status === 403 && navigate) {
        navigate("/403");
      }
      return apiError;
    }
  }

  // If error is already an Error instance, preserve its message
  if (error instanceof Error) {
    return new ApiError(error.message, 500, error);
  }

  // Handle unknown errors
  return new ApiError("An unknown error occurred", 500, error);
};
