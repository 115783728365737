import { Button } from "@chakra-ui/react";
import { BsArrowDownSquareFill } from "react-icons/bs";

interface ExportButtonProps {
  onClick: () => void;
}

export const ExportButton = ({ onClick }: ExportButtonProps) => {
  return (
    <Button variant="outline" leftIcon={<BsArrowDownSquareFill />} onClick={onClick} size={{ base: "sm", md: "md" }}>
      Export
    </Button>
  );
};
