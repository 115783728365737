import { useEffect } from "react";

import { Box, Button, Heading, Spinner, VStack } from "@chakra-ui/react";
import { SignOutButton, useClerk, useUser } from "@clerk/clerk-react";
import { Navigate } from "react-router-dom";

import { useAppState } from "~/hooks/useAppState";
import { useDbUsers } from "~/hooks/useDbUsers";

type PrivateRouteProps = {
  children: React.ReactNode;
  redirectTo?: string;
};

const RequireAuth = ({ children, redirectTo = "/login" }: PrivateRouteProps) => {
  const clerk = useClerk();
  const user = useUser();
  const { useUserInfo } = useDbUsers();
  const { data: userInfo, isLoading } = useUserInfo();
  const { setUserInfo, isAuthorized, setIsAuthorized } = useAppState(
    "setUserInfo",
    "isAuthorized",
    "setIsAuthorized"
  );

  useEffect(() => {
    if (!clerk.loaded || !user.isSignedIn) {
      return;
    }

    const firstOrganization = user.user?.organizationMemberships?.length
      ? user.user?.organizationMemberships[0].organization
      : null;
    if (firstOrganization) {
      clerk.setActive({ organization: firstOrganization });
    }
  }, [clerk.loaded, user.isSignedIn, user.user?.organizationMemberships]);

  useEffect(() => {
    if (userInfo) {
      setUserInfo(userInfo);
      setIsAuthorized(true);
    }
  }, [userInfo, setUserInfo, setIsAuthorized]);

  const isAuthenticated = user.isSignedIn;

  if (isLoading || !clerk.loaded) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="lg" />
      </Box>
    );
  }

  if (isAuthenticated && !isAuthorized) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh" width="100vw">
        <VStack>
          <Heading as="h1">Unauthorized</Heading>
          <Heading as="h2" size="sm">
            Sorry, {user.user?.primaryEmailAddress?.emailAddress} is not authorized to view this page
          </Heading>
          <Button variant="secondary" size="sm">
            <SignOutButton />
          </Button>
        </VStack>
      </Box>
    );
  }

  return isAuthenticated ? (children as React.ReactElement) : <Navigate to={redirectTo} />;
};

export default RequireAuth;
