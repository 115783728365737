import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export interface OrgStats {
  totalUniqueUsers: number;
  totalSessionsCompleted: number;
  averageStressDecrease: number;
  activeUsers: number;
  dateRangeDays: number;
  maxUsers: number | null;
}

export interface OrgStatsWithChange {
  newUsers: number;
  totalSessionsCompleted: number;
  averageStressDecrease: number;
  averageStartingStress: number;
  activeUsers: number;
  percentChanges: {
    newUsers: number;
    totalSessionsCompleted: number;
    averageStressDecrease: number;
    averageStartingStress: number;
    activeUsers: number;
  };
}

export type OverallStatsResponse = OrgStats;
export type DateRangeStatsResponse = OrgStatsWithChange;

export function useOrgStats(orgId: number | undefined): ReturnType<typeof useQuery<OverallStatsResponse>>;
export function useOrgStats(
  orgId: number | undefined,
  days: number
): ReturnType<typeof useQuery<DateRangeStatsResponse>>;
export function useOrgStats(orgId: number | undefined, days?: number) {
  const endpoint = days ? `/api/org-analytics/id/${orgId}/stats/${days}` : `/api/org-analytics/id/${orgId}/stats`;

  return useQuery({
    queryKey: ["orgStats", orgId, days],
    queryFn: async () => {
      if (!orgId) throw new Error("Organization ID is required");
      const { data } = await axios.get(endpoint);
      return data.data;
    },
    enabled: !!orgId
  });
}

export function useAllOrgStats(orgId: number | undefined) {
  const overall = useOrgStats(orgId);
  const last7Days = useOrgStats(orgId, 7);
  const last30Days = useOrgStats(orgId, 30);
  const last60Days = useOrgStats(orgId, 60);
  const last90Days = useOrgStats(orgId, 90);

  return {
    overall,
    last7Days,
    last30Days,
    last60Days,
    last90Days,
    isLoading:
      overall.isLoading || last7Days.isLoading || last30Days.isLoading || last60Days.isLoading || last90Days.isLoading
  };
}
