import { Box, Grid, List, ListItem, Text } from "@chakra-ui/react";
import { UserInfo } from "@shared/domain.models";

import { AdminItem } from "./AdminItem";

interface PendingInvite {
  email: string;
  createdAt: Date;
  expiresAt: Date;
}

interface AdminListProps {
  admins: UserInfo[];
  pendingInvites?: PendingInvite[];
}

export const AdminList = ({ admins, pendingInvites = [] }: AdminListProps) => {
  return (
    <Box borderRadius={10} border="1px solid" borderColor="gray.200" p={4} w="100%">
      <List width="100%">
        <ListItem key="header" p={4}>
          <Grid
            templateColumns={{
              base: "repeat(2, 1fr) 40px",
              md: "repeat(4, 1fr) 40px"
            }}
            gap={4}
            alignItems="center"
          >
            <Text color="gray.500">Admin Name</Text>
            <Text color="gray.500" display={{ base: "none", md: "block" }}>
              Admin Email
            </Text>
            <Text color="gray.500">Status</Text>
            <Text color="gray.500" display={{ base: "none", md: "block" }}>
              Date Added
            </Text>
          </Grid>
        </ListItem>
        {admins?.map((admin) => <AdminItem admin={admin} key={admin.id} />)}
        {pendingInvites.map((invite) => (
          <ListItem key={invite.email} p={4}>
            <Grid
              templateColumns={{
                base: "repeat(2, 1fr) 40px",
                md: "repeat(4, 1fr) 40px"
              }}
              gap={4}
              alignItems="center"
            >
              <Text color="gray.400">Pending</Text>
              <Text display={{ base: "none", md: "block" }}>{invite.email}</Text>
              <Text color="orange.500">Invited</Text>
              <Text display={{ base: "none", md: "block" }}>
                {invite.createdAt.toLocaleDateString()}
              </Text>
            </Grid>
          </ListItem>
        ))}
        {admins?.length === 0 && pendingInvites.length === 0 && <Text>No admins found.</Text>}
      </List>
    </Box>
  );
};
