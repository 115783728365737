export enum UserRole {
  SuperAdmin = "Super Admin",
  Admin = "Admin",
  NoAccess = "No Access"
}

export interface UserInfo {
  id: number;
  authId: string;
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  isSuperAdmin: boolean;
  isOrgAdmin?: boolean;
  orgId?: number;
  orgName?: string;
  createdAt: Date;
  isActive?: boolean;
}

export interface Organization {
  id?: number;
  name: string;
  domains: string[];
  serviceStartDate?: Date;
  serviceEndDate?: Date;
  isActive: boolean;
  createdAt?: Date;
  admins: UserInfo[];
  maxUsers?: number;
}

export type InviteError =
  | "User already exists"
  | "User not found"
  | "User invite already exists"
  | "User invite expired"
  | "User invite not found";

export interface AuditLog {
  id?: number;
  category: string;
  action: string;
  userId: number;
  orgId: number;
  created_at?: Date;
  user?: UserInfo;
}

export interface AnalyticsEventData {
  instanceId: string;
  userId: string;
  deviceId: string;
  sessionId: string;
  eventName: string;
  organizationName: string;
  email: string;
  extraData?: Record<string, any>;
  createdAt?: Date;
}
